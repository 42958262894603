import { FormControl, InputLabel, Select, MenuItem, Switch } from '@mui/material';
import { Box } from '@mui/system';
import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import { listBugReports, patchBugReport } from 'services/BugReportService';

const updateResolved = (row, resolved) => {
    patchBugReport(row.id, { resolved }, (response) => {
        console.log('patchBugReport', response);
    });
    // Update the resolved status of the bug report
};

const cols = [
    { id: 'createdByEmail', name: 'Created By' },
    { id: 'createdAt', name: 'Created At' },
    {
        id: 'resolved',
        name: 'Resolved',
        valueAccessor: (row) => (
            <Switch
                defaultChecked={row.resolved}
                onChange={(e) => {
                    row.resolved = e.target.checked;
                    updateResolved(row, e.target.checked);
                }}
            />
        )
    },
    { id: 'message', name: 'Message' }
];

export default function BugReports() {
    const [reports, setReports] = useState([]);

    useEffect(() => {
        listBugReports((response) => {
            setReports(response);
        });
    }, []);

    return (
        <Box>
            <h1>Bug Reports</h1>
            <DataTable rows={reports} columns={cols}></DataTable>
        </Box>
    );
}
